import React, { useState } from "react";
import logo from "../assets/logo.svg";
import { Modal } from "react-bootstrap";
import sureshImage from "../assets/suresh-image.png";
import phBadge from "../assets/badge-producthunt.svg";
import peerlistBadge from "../assets/medal3.png";

function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <footer className="container-fluid pli-footer">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-4 col-6">
          <div className="promotion-block">
            <a
              className="ph-badge"
              rel="noopener noreferrer"
              href="https://www.producthunt.com/posts/potlab-icons?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-potlab-icons"
              target="_blank"
            >
              <img
                src={phBadge}
                alt="Potlab Icons - Animated SVG icons for web projects | Product Hunt"
              />
            </a>
            <a
              className="peerlist-badge"
              
              href="https://peerlist.io/sureshdesigner"
              target="_blank"
            >
              <img
                src={peerlistBadge}
                alt="Potlab Icons - Animated SVG icons for web projects | Product Hunt"
              />
            </a>
          </div>
          {/* <div className="copyright-footer">
                          <div>&copy; 2022 <a href="https://potlabicons.com/">Potlab Icons</a></div>
                          <div className="cc-license">
                              <a rel="license" href="http://creativecommons.org/licenses/by/4.0/" title="This work is licensed under a Creative Commons Attribution 4.0 International License.">
                                  <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by/4.0/88x31.png" />
                              </a>
                          </div>
                      </div> */}
        </div>
        <div className="col-md-6 col-lg-4 col-6 text-center">
          Made with love by{" "}
          <a
            className="font-weight-bold"
            href="https://www.linkedin.com/in/suresh-uix/"
          >
            Suresh
          </a>
        </div>
        <div className="col-md-6 col-lg-4 col-6 text-center">
          <div className="footer-social">
            <ul>
              <li>
                <a href="https://dribbble.com/suresh_uix">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.6726 20.8435C15.5 14 12.5 8.00003 8.5 2.62964" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.06653 10.8406C6.00004 11 15.2829 10.5 19.1415 5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.9677 12.81C15.3438 10.8407 7.50002 14.0001 5.23145 19.3613" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                </a>
              </li>
              <li>
                <a href="https://twitter.com/suresh_uix">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 3.01006C23 3.01006 20.9821 4.20217 19.86 4.54006C19.2577 3.84757 18.4573 3.35675 17.567 3.13398C16.6767 2.91122 15.7395 2.96725 14.8821 3.29451C14.0247 3.62177 13.2884 4.20446 12.773 4.96377C12.2575 5.72309 11.9877 6.62239 12 7.54006V8.54006C10.2426 8.58562 8.50127 8.19587 6.93101 7.4055C5.36074 6.61513 4.01032 5.44869 3 4.01006C3 4.01006 -1 13.0101 8 17.0101C5.94053 18.408 3.48716 19.109 1 19.0101C10 24.0101 21 19.0101 21 7.51006C20.9991 7.23151 20.9723 6.95365 20.92 6.68006C21.9406 5.67355 23 3.01006 23 3.01006Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/potlab_icons/">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M17.5 6.51L17.51 6.49889" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/potlabicons/">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 2H14C12.6739 2 11.4021 2.52678 10.4645 3.46447C9.52678 4.40215 9 5.67392 9 7V10H6V14H9V22H13V14H16L17 10H13V7C13 6.73478 13.1054 6.48043 13.2929 6.29289C13.4804 6.10536 13.7348 6 14 6H17V2Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </footer>
  );
}

export default Footer;
