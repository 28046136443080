import React from "react";
// import BuymeaCoffee from '../assets/bmc-top.svg';
import logo from "../assets/logo.svg";
// import phBadge from '../assets/top-post-badge.svg';
import head from "../assets/head1.png"
import head1 from "../assets/free.png"
import discordLogo from "../assets/discord-logo.svg";
import Dropdown from "react-bootstrap/Dropdown";

function Header({ datatochild }) {
  return (
    <div>
      <div className="announcement-bar">
        {/* <span>
      Hooray! Potlabicons showcased in peerlist.</span><a href="https://peerlist.io/sureshdesigner/project/potlab-icons" target="_blank">Upvote</a><span>to show love!</span> */}
      <a href="https://visage.framer.website/" target="_blank">Grab the Professional FRAMER Website Template for Spa Businesses with stunning layouts.</a>
      </div>
      <div className="pli-header">
        <div className="container-fluid">
          <nav className="navbar-row">
            <div className="left-nav">
              <div className="brand">
                <a className="navbar-brand" href="http://www.potlabicons.com/">
                  <img src={logo} alt="Logo" />
                  Potlab Icons
                </a>
              </div>
            </div>

            <div className="right-nav">
              {/* Mobile menus */}
              <div className="mobile-menus">
                <a
                  href="https://discord.com/invite/t9wUTmrKjK"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Join on Discord"
                  className=" "
                >
                  <img
                    src={discordLogo}
                    className="discord-logo"
                    alt="discord_logo"
                  />
                </a>
                <Dropdown align="end">
                  <Dropdown.Toggle variant="secondary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://www.buymeacoffee.com/suresh_uix"
                      target="_blank"
                      className="donate-text"
                    >
                      Donate
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://www.linkedin.com/in/suresh-uix/"
                      target="_blank"
                      className=""
                    >
                      About Suresh
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfadWNmiUX7wLLRXTveUDuN80AtF_GMD38bdvQXcT3-XITzEQ/viewform"
                      target="_blank"
                      title="potlabicons@gmail.com"
                    >
                      Request Icons
                    </Dropdown.Item>
                    <Dropdown.Item href="https://potlabicons.com/v1/">
                      Version 1
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Desktop menus */}
              <a
                href="https://www.buymeacoffee.com/suresh_uix"
                target="_blank"
                className="mr-3 donate-text desktop-menu"
              >
                Donate
              </a>
              <a
                href="https://www.linkedin.com/in/suresh-uix/"
                target="_blank"
                className="mr-3 desktop-menu"
              >
                About
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfadWNmiUX7wLLRXTveUDuN80AtF_GMD38bdvQXcT3-XITzEQ/viewform"
                target="_blank"
                className="mr-3 desktop-menu"
                title="potlabicons@gmail.com"
              >
                Request Icons
              </a>
              <a
                href="https://discord.com/invite/t9wUTmrKjK"
                target="_blank"
                rel="noopener noreferrer"
                title="Join on Discord"
                className="desktop-menu"
              >
                <img
                  src={discordLogo}
                  className="discord-logo"
                  alt="discord_logo"
                />
              </a>
              <a
                href="https://potlabicons.com/v1/"
                className="ml-3 v1-icon desktop-menu"
                title="Looking for Version 1"
              >
                <div className="v1">V1</div>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

  );
}

export default Header;
