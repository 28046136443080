import React from 'react';
import CarbonAds from "react-carbon-ads"
function Intro() {
    return(
        
        <section className="primary-info-block">
            <div className="container">
                <div className="row">
                    <div className='col-md-8 col-12 mx-auto'>
                    <div className="caption text-center">
                    Carefully Animated SVG icons for your next Projects.
                        </div>
                        <p className="desc text-center">The clean icon set you were looking for, ready to use in web, mobile development and wherever you want. Completely free and open source under <a href='https://creativecommons.org/licenses/by/4.0/'>CC 4.0</a>.  </p>
                    </div>
                    
                </div>
                <div className='other-container'>
                <div className=''>
                        <div className='tools-section'>
                        <div className='tool-caption'>Use in any design or code tool</div>
                            <div className='icons-container'>
                                
                                <div className='tools'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="32" fill="none" viewBox="0 0 18 32">
                                <path fill="#0ACF83" d="M5.044 28c2.232 0 4.043-1.792 4.043-4v-4H5.044C2.812 20 1 21.791 1 24s1.812 4 4.044 4Z"/>
                                <path fill="#A259FF" d="M1 16c0-2.208 1.812-4 4.044-4h4.043v8H5.044C2.812 20 1 18.207 1 16Z"/>
                                <path fill="#F24E1E" d="M1 8c0-2.208 1.812-4 4.044-4h4.043v8H5.044C2.812 12 1 10.208 1 8Z"/>
                                <path fill="#FF7262" d="M9.086 4h4.044c2.232 0 4.044 1.792 4.044 4s-1.812 4-4.044 4H9.086V4Z"/>
                                <path fill="#1ABCFE" d="M17.174 16c0 2.208-1.812 4-4.044 4-2.232 0-4.044-1.792-4.044-4s1.812-4 4.044-4c2.232 0 4.044 1.792 4.044 4Z"/>
                                </svg>

                                </div>
                                <div className='tools'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" fill="none" viewBox="0 0 28 32">
  <path fill="#FDB300" d="M6.794 4.79 14.278 4l7.483.79 5.794 7.764-13.277 15.434L1 12.554 6.794 4.79Z"/>
  <path fill="#EA6C00" d="m6.378 12.554 7.9 15.434L1 12.554h5.378Z"/>
  <path fill="#EA6C00" d="m22.177 12.554-7.9 15.434 13.278-15.434h-5.378Z"/>
  <path fill="#FDAD00" d="M6.378 12.554h15.799l-7.9 15.434-7.9-15.434Z"/>
  <path fill="#FDD231" d="m14.277 4-7.483.79-.416 7.764L14.278 4Z"/>
  <path fill="#FDD231" d="m14.277 4 7.484.79.416 7.764L14.277 4Z"/>
  <path fill="#FDAD00" d="M27.555 12.554 21.761 4.79l.416 7.764h5.378ZM1 12.554 6.794 4.79l-.416 7.764H1Z"/>
  <path fill="#FEEEB7" d="m14.277 4-7.9 8.554h15.8L14.277 4Z"/>
</svg>

                                </div>
                                <div className='tools'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" fill="none" viewBox="0 0 24 32">
  <path fill="#470137" d="M4.25 4h15.5C22.1 4 24 5.949 24 8.359v15.282C24 26.051 22.1 28 19.75 28H4.25C1.9 28 0 26.051 0 23.641V8.359C0 5.949 1.9 4 4.25 4Z"/>
  <path fill="#FF61F6" d="m12.62 10.308-3 5.077 3.2 5.384c.02.041.03.082.02.123-.01.041-.05.01-.11.02h-2.29c-.16 0-.27-.01-.34-.112-.21-.43-.43-.851-.64-1.282-.21-.42-.44-.851-.68-1.292-.24-.441-.48-.882-.72-1.334h-.02c-.21.441-.44.882-.67 1.323-.23.441-.46.883-.68 1.313-.23.431-.46.872-.69 1.293-.04.102-.12.112-.23.112h-2.2c-.04 0-.07.02-.07-.03a.145.145 0 0 1 .02-.113l3.11-5.231-3.03-5.261c-.03-.042-.04-.083-.02-.103.02-.03.06-.041.1-.041h2.27c.05 0 .1.01.14.02.04.02.07.052.1.093.19.44.41.882.64 1.323.24.44.47.872.72 1.302.24.431.46.862.67 1.303h.02c.21-.451.43-.892.65-1.323.22-.43.45-.862.68-1.293.23-.43.45-.871.67-1.292.01-.04.03-.082.06-.102.04-.02.08-.031.13-.02h2.11c.05-.011.1.02.11.07.01.011-.01.052-.03.073Zm4.62 10.82c-.74.01-1.48-.143-2.15-.461a3.519 3.519 0 0 1-1.51-1.395c-.37-.626-.55-1.405-.55-2.339-.01-.759.18-1.507.55-2.164.38-.666.93-1.22 1.59-1.59.7-.4 1.54-.594 2.53-.594.05 0 .12 0 .21.01s.19.01.31.02v-3.24c0-.072.03-.113.1-.113h2.03c.05-.01.09.03.1.071v9.785c0 .185.01.39.02.615.02.216.03.42.04.595 0 .072-.03.134-.1.164a7.5 7.5 0 0 1-1.63.493c-.51.092-1.02.143-1.54.143Zm.98-2.051v-4.513c-.09-.02-.18-.04-.27-.051-.11-.01-.22-.02-.33-.02-.39 0-.78.081-1.13.266-.34.174-.63.43-.85.759-.22.328-.33.77-.33 1.303-.01.359.05.717.17 1.056.1.277.25.523.45.728.19.185.42.328.68.41.27.093.55.134.83.134.15 0 .29-.01.42-.02a.86.86 0 0 0 .36-.052Z"/>
</svg>

                                </div>
                                <div className='tools'>

                                <svg height="32" viewBox="-11.9 -2 1003.9 995.6" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12.1 353.9s-24-17.3 4.8-40.4l67.1-60s19.2-20.2 39.5-2.6l619.2 468.8v224.8s-.3 35.3-45.6 31.4z" fill="#2489ca"/><path d="M171.7 498.8 12.1 643.9s-16.4 12.2 0 34l74.1 67.4s17.6 18.9 43.6-2.6L299 614.4z" fill="#1070b3"/><path d="m451.9 500 292.7-223.5-1.9-223.6s-12.5-48.8-54.2-23.4L299 384z" fill="#0877b9"/><path d="M697.1 976.2c17 17.4 37.6 11.7 37.6 11.7l228.1-112.4c29.2-19.9 25.1-44.6 25.1-44.6V159.7c0-29.5-30.2-39.7-30.2-39.7L760 24.7c-43.2-26.7-71.5 4.8-71.5 4.8s36.4-26.2 54.2 23.4v887.5c0 6.1-1.3 12.1-3.9 17.5-5.2 10.5-16.5 20.3-43.6 16.2z" fill="#3c99d4"/></svg>
                                </div>
                                <div className='tools mr-0'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#4353FF"/>
<path d="M15.525 10.575L14.4375 14.0025C14.4375 13.74 13.6875 8.05499 13.6875 8.05499C11.9625 8.05499 11.0475 9.27749 10.5675 10.575L9.23996 14.01C9.23996 13.7625 9.05246 10.605 9.05246 10.605C8.98842 9.92146 8.67594 9.28514 8.17419 8.81657C7.67244 8.34799 7.01627 8.07969 6.32996 8.06249L7.73996 16.6725C9.53996 16.6725 10.515 15.45 11.0175 14.1525L12.1425 11.2275C12.1425 11.3475 12.8925 16.6725 12.8925 16.6725C13.6285 16.6715 14.3451 16.4362 14.9385 16.0007C15.5318 15.5651 15.971 14.9519 16.1925 14.25L18.7425 8.05499C16.9425 8.05499 15.9975 9.27749 15.5175 10.575H15.525Z" fill="white"/>
</svg>

                                </div>
                            </div>
                        </div>
                    </div>
                <div className=''>
                <div className='ad-container'>
            
            <CarbonAds carbonUrl="//cdn.carbonads.com/carbon.js?serve=CEAI453M&placement=potlabiconscom"/>
            
            </div>
                </div>
                    </div>
            </div>
        </section>
    )
}

export default Intro;