import React, { Component, useState } from 'react'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import phBadge from "../assets/badge-producthunt.svg";
// import CarbonAds from "react-carbon-ads"

export class IconsList extends Component {
    constructor() {
        super();
        this.state = ({
            id: 0,
            iconsList: [],
            filter: "",
            copySuccess: false,
            dataValue: [],
            selectedCategory: [],
            selectionList: [],
            // filterLabel:"All",
            defaultPlaceHolder: true,
            labelvale: []
        })
    }


    componentDidMount() {
        axios.get(`https://potlabicons.com/admin/api/icon.php`)
            .then(res => {
                const iconsList = res.data;
                this.setState({ iconsList })
                //console.log(iconsList);

            })

        axios.get(`https://potlabicons.com/admin/api/category.php`)
            .then(res => {
                const dataValue = res.data;
                const labelvale = res.data;
                this.setState({ dataValue, labelvale })
                // console.log(dataValue);
            })

    }


    onChange = ({ target: { value } }) => {
        this.setState({ dataValue: value });
    }


    handleModal(icons) {
        // this.setState({ show: !this.state.show });
        this.setState({ id: Number(icons) });
        // axios.get(`http://potlabicons.com/pli-admin/potlab/api/icon.php`)
        //     .then(res => {
        //         const iconsList = res.data;
        //         this.setState({ iconsList })
        //         // console.log(iconsList);
        //     })
        this.setState({ copySuccess: false })
        this.setState({ defaultPlaceHolder: false })
    }

    handleChange = event => {
        this.setState({ filter: event.target.value });
    };


    copyCodeToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
        this.setState({ copySuccess: true })
        setTimeout(() => {
            this.setState({ copySuccess: false });
        }, 2000)
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value, })
        this.setState({ selectedCategory: e.target.value })
        // let inx=this.state.dataValue.find(a=>a.id=e.target.value)
        // this.setState({filterLabel:inx.name})
        if (e.target.value != "All") {
            const labelvale = this.state.dataValue.filter(a => a.id == e.target.value)
            this.setState({ labelvale })
        }
        else {
            const labelvale = this.state.dataValue
            this.setState({ labelvale })
        }
    }

    render() {
        const { filter, iconsList } = this.state;
        const lowercasedFilter = filter.toLowerCase();
        const filteredData = iconsList.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toLowerCase().includes(lowercasedFilter)
            );
        });



        // console.log("check>>>",filteredData);
        //console.log(this.state.selectOptions);

        return (
            <React.Fragment>
                <div>
                    <div className=''>
                        <div className=''>


                            <div className="icons-container-v3">
                                <section className="container sticky-filter">
                                    <div className='row'>
                                        <div className='col-md-12'>

                                            <div className="filter-section">
                                                <div className="row">
                                                    <div className="col-md-4 col-lg-4 col-8">
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="ri-search-line"></i></span>
                                                            </div>
                                                            <input type="text" className="form-control search-input" placeholder="Search Icons" value={filter} onChange={this.handleChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-lg-4 col-xl-3  col-4">
                                                        <select name="" id="" defaultValue={'All'} className="form-control mb-2" value={this.state.selectValue} onChange={this.handleInput}>
                                                            <option value="All">All</option>
                                                            {this.state.dataValue.map(cname => <option key={cname.id} value={cname.id}>{cname.name}</option>)}
                                                        </select>
                                                    </div>
                                                    {/* <div className="col-md-1">
                                <button className="btn text-primary play-animation-btn"><i className="ri-play-fill"></i></button>
                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                <section className="container pli-icon-view">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="pli-icons-container">
                                                {this?.state?.labelvale?.map(cname =>
                                                    <div className="pli-categories-row">

                                                        <div className='icon-category-name'>{cname.name}</div>
                                                        <div className="pli-icon-grid">
                                                            {filteredData.map((icons, i) => {

                                                                if (icons.category_id == cname?.id) {
                                                                    return (<div className="icon-holder" key={i} onClick={this.handleModal.bind(this, icons.id)}>
                                                                        <div className="svg-icon" dangerouslySetInnerHTML={{ __html: icons.icon_code }}></div>
                                                                    </div>)
                                                                }
                                                                //    else if(this.state.selectedCategory ==""){
                                                                //        return(<div className="icon-holder" key={i} onClick={this.handleModal.bind(this, icons.id)}>
                                                                //                <div className="svg-icon" dangerouslySetInnerHTML={{ __html: icons.icon_code }}></div>
                                                                //            </div>)
                                                                //    }
                                                                //    else if(this.state.selectedCategory =="All"){
                                                                //        return(<div className="icon-holder" key={i} onClick={this.handleModal.bind(this, icons.id)}>
                                                                //                <div className="svg-icon" dangerouslySetInnerHTML={{ __html: icons.icon_code }}></div>
                                                                //            </div>)
                                                                //    }



                                                            }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>



                        </div>
                        <div className='float-preview'>
                            <div className=' '>

                                <div>
                                    <div className='preview-holder'>
                                        <div>
                                            {filteredData.map((icons, i) =>
                                                <div key={i}>
                                                    {this.state.id == icons.id
                                                        ?
                                                        <div className='icon-previewer-v3 gradient-border'>

                                                            <div className='holder'>
                                                                <textarea className="hide-textarea" ref={(textarea) => this.textArea = textarea} value={icons.icon_code} />
                                                                <div dangerouslySetInnerHTML={{ __html: icons.icon_code }}></div>
                                                            </div>
                                                            <div className='icon-info'>


                                                                <h6 className="preview-icon-name">
                                                                    {icons.icon_name}
                                                                </h6>



                                                                <button className="btn btn-copy" onClick={() => this.copyCodeToClipboard()}>
                                                                    {this.state.copySuccess ? <span className="icon-copied"> 👍 Copied</span> : <span>Copy SVG</span>}
                                                                </button>
                                                                <button className="btn btn-close close-btn" onClick={() => { this.handleModal() }}>
                                                                    <i className="ri-close-fill"></i>
                                                                </button>
                                                            </div>
                                                            <div className="announcement-bar">

                                                            <a href="https://visage.framer.website/" target="_blank">Grab the Professional FRAMER Website Template for Spa Businesses with stunning layouts.</a>

                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {/* Icon preview modal */}
                <Modal show={this.state.show} centered dialogClassName="fullscreenmodal preview-icon-popup">
                    <Modal.Body>
                        <div className=" preview-icons">
                            <div className="top-actions">
                                {/* <div className="show-loves" data-toggle="tooltip" title="Loved by others" data-placement="right">
                                    <div className="icon"><i className="ri-heart-3-fill"></i></div>
                                    <div className="count">
                                        <h6>150</h6>
                                    </div>
                                </div> */}
                                <div></div>
                                <button className="btn btn-close" onClick={() => { this.handleModal() }}>
                                    <i className="ri-close-fill"></i>
                                </button>
                            </div>


                            <div className="icon-container">
                                {filteredData.map((icons, i) =>
                                    <div key={i}>
                                        {this.state.id == icons.id
                                            ?
                                            <div className="icon-holder">
                                                <textarea className="hide-textarea" ref={(textarea) => this.textArea = textarea} value={icons.icon_code} />
                                                <div dangerouslySetInnerHTML={{ __html: icons.icon_code }}></div>

                                                <div className="icon-info">
                                                    <h6 className="icon-name">
                                                        {icons.icon_name}
                                                    </h6>
                                                    <div className="download-action">
                                                        <button className="btn btn-copy-icon" onClick={() => this.copyCodeToClipboard()}>
                                                            {this.state.copySuccess ? <span className="icon-copied"> Copied 👍</span> : <span>Copy Icon</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                )}
                            </div>




                            {/* <div className="icon-info">
                                <h6 className="icon-name">
                                    Preview Icon name
                                </h6>

                                <div className="download-action">
                                    <button className="btn btn-copy-icon" onClick={() => this.copyCodeToClipboard()}>
                                        {this.state.copySuccess ? <span className="icon-copied"> Copied 👍</span> : <span>Copy Icon</span>}
                                    </button>
                                </div>

                            </div> */}
                        </div>
                        <div>
                            <div>

                            </div>

                        </div>

                    </Modal.Body>

                </Modal>

                {/* <section className="container old-icons-update-card">
                 
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                          <div className="update-card">
                            <div className="somediv">
                             <h2>Need Old website</h2>
                             <p>We are upgrading our website for the next version. The site will update day by day. We kept all the icons for your experience. Checkout out old icons.  </p>
                             </div>
                             <a href="http://potlabicons.com/v1" className="btn btn-light">Check Out </a>
                          </div>
                      </div>

                    </div>
                </section> */}




            </React.Fragment>
        )
    }
}

export default IconsList
