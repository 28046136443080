import React from 'react'; 
import Header from './components/Header';
import IconsList from './components/IconsList';

 import Intro from './components/Intro';
// import Ads from './components/Ads';
// import HowWork from './components/HowWork';
// import Features from './components/Features';
// import Request from './components/Request';
import Footer from './components/Footer'; 
// import CarbonAds from "react-carbon-ads"

import './App.scss';
import './pli.v2.scss'; 

function App() {
  return (
    <div >
    <Header />
    <Intro/>
    <IconsList/>
    <Footer/>
   
    
    {/* <Ads/> */}
    
    {/* <HowWork/> */}
    {/* <Features/> */}
    {/* <Request/> */}
    
  </div>
  );
}

export default App;
